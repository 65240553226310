import React, { useEffect } from 'react';
import {
  ActionIcon,
  Button,
  Card,
  Dialog,
  Group,
  Image,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';
import { UserStatus } from './UserStatus';
import { constants } from '../utils/config';

const WelcomeDialogKey = 'SeenWelcomeDialogV1';

export const WelcomeDialog = () => {
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    if (sessionStorage.getItem(WelcomeDialogKey) !== 'true') {
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      p={0}
      opened={opened}
      withCloseButton
      onClose={() => {
        sessionStorage.setItem(WelcomeDialogKey, 'true');
        close();
      }}
      size="lg"
      radius="md"
    >
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section>
          <Image
            src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png"
            height={160}
            alt="Norway"
          />

          <div style={{ position: 'absolute', top: 10, right: 10 }}>
            <ActionIcon
              color="white"
              variant="subtle"
              aria-label="Close the welcome dialog."
            >
              <IconX style={{ width: '70%', height: '70%' }} stroke={1.5} />
            </ActionIcon>
          </div>
        </Card.Section>

        <Group justify="space-between" mt="md" mb="xs">
          <Text fw={500}>Welcome to {constants.appName}</Text>
          <UserStatus />
        </Group>

        <Text size="sm" c="dimmed" mb="xs">
          Explore hand-curated products tailored to your interests!
        </Text>

        <Text size="sm" c="dimmed">
          We leverage our expertise along with recommendations sent to us,
          categorizing items by subreddit to help you find products you love or
          the perfect gift for others in those communities.
        </Text>

        <Group justify="flex-end" mt="md">
          <Button
            variant="outline"
            onClick={() => {
              sessionStorage.setItem(WelcomeDialogKey, 'true');
              close();
            }}
          >
            Browse Subreddits
          </Button>

          <Button
            variant="filled"
            onClick={() => {
              sessionStorage.setItem(WelcomeDialogKey, 'true');
              close();
            }}
          >
            Get Started
          </Button>
        </Group>

        {/* <Divider mt="md" />

        <Group align="flex-end" mt="md">
          <TextInput
            placeholder="bananas for r/wallstreetbets"
            style={{ flex: 1 }}
          />
          <Button
            onClick={() => {
              sessionStorage.setItem(WelcomeDialogKey, 'true');
              close();
            }}
          >
            Recommend
          </Button>
        </Group> */}
      </Card>
    </Dialog>
  );
};
