// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import React from 'react';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import type { GatsbyBrowser } from 'gatsby';
import { Sidebar } from './src/components/Sidebar';
import { WelcomeDialog } from './src/components/WelcomeDialog';
import { theme } from './src/theme';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/notifications/styles.css';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return (
    <MantineProvider theme={theme}>
      <Notifications />
      <WelcomeDialog />
      <Sidebar>{element}</Sidebar>
    </MantineProvider>
  );
};
