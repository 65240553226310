import React, { useCallback, useEffect, useState } from 'react';
import {
  Anchor,
  Center,
  Divider,
  NavLink,
  ScrollArea,
  Text,
} from '@mantine/core';
import {
  IconBook,
  IconBrandReddit,
  IconFileText,
  IconHandStop,
  IconHelp,
  IconHomeFilled,
  IconSearch,
  IconTrendingUp,
  IconX,
} from '@tabler/icons-react';
import { Link } from 'gatsby';
import { constants } from '../utils/config';

const iconSize = 14;
const iconStroke = 1.5;

type MenuItem = {
  name: string;
  icon: JSX.Element;
  to: string;
};

const primaryItems = [
  {
    name: 'Home',
    icon: <IconHomeFilled size={iconSize} stroke={iconStroke} />,
    to: '/',
  },
  {
    name: 'Popular',
    icon: <IconTrendingUp size={iconSize} stroke={iconStroke} />,
    to: '/popular',
  },
];

const resources = [
  {
    name: `About ${constants.appName}`,
    icon: <IconBook size={iconSize} stroke={iconStroke} />,
    to: '/about',
  },
  {
    name: `Help`,
    icon: <IconHelp size={iconSize} stroke={iconStroke} />,
    to: '/help',
  },
  {
    name: `Privacy Policy`,
    icon: <IconHandStop size={iconSize} stroke={iconStroke} />,
    to: '/legal/privacy',
  },
  {
    name: `Terms of Service`,
    icon: <IconFileText size={iconSize} stroke={iconStroke} />,
    to: '/legal/terms',
  },
];

export type MenuItemsProps = {
  toggle: () => void;
};

export const MenuItems = ({ toggle }: MenuItemsProps) => {
  const [recent, setRecent] = useState<string[] | null>();

  useEffect(() => {
    // Done in a useEffect to guarantee this runs in the browser as opposed to Node.js
    const recentSubredditsJson = sessionStorage.getItem('RecentSubreddits');
    if (recentSubredditsJson) {
      const recentSubreddits = JSON.parse(recentSubredditsJson) as string[];
      setRecent(recentSubreddits);
    }
  }, []);

  const renderItem = useCallback(
    (item: MenuItem) => {
      return (
        <NavLink
          key={item.name}
          label={item.name}
          color="black"
          style={{ borderRadius: '4px' }}
          leftSection={item.icon}
          onClick={toggle}
          onKeyDown={toggle}
          component={Link}
          to={item.to}
        />
      );
    },
    [toggle],
  );

  const clearRecent = useCallback(() => {
    sessionStorage.removeItem('RecentSubreddits');
    setRecent(null);
  }, []);

  return (
    <ScrollArea type="scroll">
      {primaryItems.map((item) => renderItem(item))}
      <NavLink
        label="Explore"
        color="black"
        style={{ borderRadius: '4px' }}
        leftSection={<IconSearch size={iconSize} stroke={iconStroke} />}
        onClick={() => console.log('TODO')}
        onKeyDown={() => console.log('TODO')}
      />

      <Divider mt="md" mb="md" />

      {recent && recent.length > 0 ? (
        <>
          <NavLink label="Recent" defaultOpened childrenOffset="xs">
            {recent.map((item) =>
              renderItem({
                name: item,
                icon: <IconBrandReddit size={iconSize} stroke={iconStroke} />,
                to: `/${item}`,
              }),
            )}
            <NavLink
              label="Clear recent"
              color="black"
              style={{ borderRadius: '4px' }}
              leftSection={<IconX size={iconSize} stroke={iconStroke} />}
              onClick={clearRecent}
              onKeyDown={clearRecent}
            />
          </NavLink>
          <Divider mt="md" mb="md" />
        </>
      ) : null}

      <NavLink label="Resources" defaultOpened childrenOffset="xs">
        {resources.map((item) => renderItem(item))}
      </NavLink>

      <Center mt="lg" mb="sm">
        <Anchor
          underline="hover"
          c="black"
          onClick={toggle}
          onKeyDown={toggle}
          component={Link}
          to="/legal/terms"
        >
          <Text size="10px">
            © {constants.companyName} {constants.year}.
          </Text>
        </Anchor>
      </Center>
    </ScrollArea>
  );
};
