import React, { useState } from 'react';
import { rem, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { constants } from '../utils/config';

export const Search = () => {
  const [search, setSearch] = useState('');

  return (
    <TextInput
      w="50%"
      miw={120}
      maw={560}
      radius="xl"
      placeholder={`Search ${constants.appName}`}
      leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} />}
      value={search}
      onChange={(event) => setSearch(event.currentTarget.value)}
    />
  );
};
