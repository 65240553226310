import React, { PropsWithChildren } from 'react';
import { AppShell, Burger, Group } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { AppLogoText } from './AppLogoText';
import { AppText } from './AppText';
import { MenuItems } from './MenuItems';
import { Search } from './Search';
import { UserStatus } from './UserStatus';

export type SidebarProps = PropsWithChildren<object>;

export const Sidebar = ({ children }: SidebarProps) => {
  const [opened, { toggle }] = useDisclosure();
  const isSmallScreen = useMediaQuery(`(max-width: 61.9375em)`);

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 260,
        breakpoint: 'md',
        collapsed: { mobile: !opened },
      }}
    >
      <AppShell.Header>
        <Group h="100%" px="md" justify="space-between">
          <Group>
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="md"
              size="sm"
            />
            {isSmallScreen ? <AppText /> : <AppLogoText />}
          </Group>
          <Search />
          <UserStatus />
        </Group>
      </AppShell.Header>

      <AppShell.Navbar p="xs">
        <MenuItems toggle={toggle} />
      </AppShell.Navbar>

      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
};
