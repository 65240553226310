import React, { useEffect, useState } from 'react';
import { Badge } from '@mantine/core';

const userStatusDurationKey = 'UserDuration';

export const UserStatus = () => {
  const [duration, setDuration] = useState('0');

  useEffect(() => {
    // Done in a useEffect to guarantee this runs in the browser as opposed to Node.js
    const updatedDuration = sessionStorage.getItem(userStatusDurationKey);
    if (updatedDuration) {
      setDuration(updatedDuration);
    }
  }, []);

  if (duration && parseInt(duration, 10) > 0) {
    return <Badge color="red">expert</Badge>;
  }

  return <Badge color="green">newb</Badge>;
};
