import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const AppLogo = () => {
  return (
    <div style={{ backgroundColor: 'white', borderRadius: '4px' }}>
      <StaticImage
        height={32}
        width={32}
        src="../images/midl.svg"
        alt=""
        role="presentation"
      />
    </div>
  );
};
